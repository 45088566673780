/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzgwODRiMyIsIj9lcjwjZDlkYWU4IiwiO2VyPCM2MzY3OWF$LCIlPmBePCMzNWNjYWUiLCI~ZXI8I2MyZjBlNyIsIjtlcjwjMjJiOTk0fiwid2Fybj5gXjwjZmYwMDAwIiwiP2VyPCNmZmIzYjMiLCI7ZXI8I2ZmMDAwMH4sIj9UZXh0PCMwMDAwMDAiLCI~PTwjZmFmYWZhIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00fixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMX4sYEA8YnV0dG9ufixgQDxjYXB0aW9ufixgQDxpbnB1dCIsInNpemU$bnVsbH1dLCJpY29uczxGaWxsZWQiLCI~bmVzcz5mYWxzZSwidmVyc2lvbj4xMX0=
*/

@import "~@angular/material/theming";

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.

// Fonts
@import "https://fonts.googleapis.com/css?family=Material+Icons";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400,500");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');



$fontConfig: (


  display-4: mat-typography-level(112px, 112px, 300, "Montserrat", -0.0134em),
  display-3: mat-typography-level(56px, 56px, 400, "Montserrat", -0.0089em),
  display-2: mat-typography-level(45px, 48px, 400, "Montserrat", 0em),
  display-1: mat-typography-level(34px, 40px, 400, "Montserrat", 0.0074em),
  headline: mat-typography-level(24px, 32px, 400, "Montserrat", 0em),
  title: mat-typography-level(20px, 32px, 500, "Montserrat", 0.0075em),
  subheading-2: mat-typography-level(18px, 28px, 400, "Montserrat", 0.0094em),
  subheading-1: mat-typography-level(18px, 28px, 500, "Montserrat", 0.0067em),
  body-2: mat-typography-level(15px, 24px, 500, "Montserrat", 0.0179em),
  body-1: mat-typography-level(15px, 15px, 400, "Montserrat", 0.0179em),
  button: mat-typography-level(15px, 15px, 500, "Montserrat", 0.0893em),
  caption: mat-typography-level(15px, 18px, 400, "Montserrat", 0.0333em),
  input: mat-typography-level(inherit, 1.125, 400, "Montserrat", 1.5px),
);

// definición de colores GLOBALES

$color-1: #0081C9;
$color-2: #00EDFF;
$color-3: #D741A7;
$color-4: #3A1772;
$color-5: #b9b9b9;

.color-1 {
  color: $color-1;
}
.color-2 {
  color: $color-2;
}
.color-3 {
  color: $color-3;
}
.color-4 {
  color: $color-4;
}
.color-5 {
  color: $color-5;
}

.bg-color-1 {
  background-color: $color-1;
  color: #fff;
}
.bg-color-2 {
  background-color: $color-2;
}
.bg-color-3 {
  background-color: $color-3;
  color: #fff;
}
.bg-color-4 {
  background-color: $color-4;
  color: #fff;
}
.bg-color-5 {
  background-color: $color-5;
}


/* start ESTILOS GLOBALES consultaveterinaria */
* {
  line-height: 1.4em;
}

html, body {
  max-width: 100%;
  overflow-x: hidden;
}

.gradient-button {
background: rgb(0,129,201);
background: linear-gradient(90deg, rgba(0,129,201,1) 0%, rgba(0,237,255,1) 100%);
color: #fff;
}

.blue-button {
  background: rgb(0,129,201);
 
  color: #fff;
  }

.banner-blog-header {
  color: $color-1;
  
}

/* ===== Scrollbar CSS ===== */
  /* Firefox */
  * {
    scrollbar-width: auto;
    scrollbar-color: #0081c9 #ffffff;
  }

  /* Chrome, Edge, and Safari */
  *::-webkit-scrollbar {
    width: 16px;
  }

  *::-webkit-scrollbar-track {
    background: #ffffff;
  }

  *::-webkit-scrollbar-thumb {
    background-color: #0081c9;
    border-radius: 10px;
    border: 3px solid #ffffff;
  }


// support button styles
.support-button {
  z-index: 999999;
  position: fixed;
  bottom: 0.5rem;
  right: 1rem;
  background-color: $color-5;
  border: 2px solid #fff;
  border-radius: 5px;
  color: #fff;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  font-size: 13px;

}

/* end ESTILOS GLOBALES consultaveterinaria */

.active-class-group-list {
  color: green;
  font-weight: bold;
}

.non-active-class-group-list {
  color: grey;
  font-weight: bold;
}
.free-classes-message {
  font-size: 1.4;
  font-weight: bold;
  color: darkgreen;
}
// Foreground Elements

// Light Theme Text
$dark-text: #313131;
$dark-primary-text: white;
$dark-accent-text: rgba($dark-text, 0.54);
$dark-disabled-text: rgba($dark-text, 0.38);
$dark-dividers: rgba($dark-text, 0.12);
$dark-focused: rgba($dark-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

// Dark Theme text
$light-text: #ffffff;
$light-primary-text: $light-text;
$light-accent-text: rgba($light-primary-text, 0.7);
$light-disabled-text: rgba($light-primary-text, 0.5);
$light-dividers: rgba($light-primary-text, 0.12);
$light-focused: rgba($light-primary-text, 0.12);

$mat-dark-theme-foreground: (
  base: $light-text,
  divider: $light-dividers,
  dividers: $light-dividers,
  disabled: $light-disabled-text,
  disabled-button: rgba($light-text, 0.3),
  disabled-text: $light-disabled-text,
  elevation: black,
  hint-text: $light-disabled-text,
  secondary-text: $light-accent-text,
  accent-text: $light-accent-text,
  icon: $light-text,
  icons: $light-text,
  text: $light-text,
  slider-min: $light-text,
  slider-off: rgba($light-text, 0.3),
  slider-off-active: rgba($light-text, 0.3),
);

// Background config

// Light bg
$light-background: #fafafa;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten(#2c2c2c, 20%);
$dark-bg-alpha-4: rgba(#2c2c2c, 0.04);
$dark-bg-alpha-12: rgba(#0081c9, 0.2);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

// Dark bg
$dark-background: #2c2c2c;
$dark-bg-lighter-5: lighten($dark-background, 5%);
$dark-bg-lighter-10: lighten($dark-background, 10%);
$dark-bg-lighter-20: lighten($dark-background, 20%);
$dark-bg-lighter-30: lighten($dark-background, 30%);
$light-bg-alpha-4: rgba(#fafafa, 0.04);
$light-bg-alpha-12: rgba(#fafafa, 0.12);

// Background palette for dark themes.
$mat-dark-theme-background: (
  background: $dark-background,
  status-bar: $dark-bg-lighter-20,
  app-bar: $dark-bg-lighter-5,
  hover: $light-bg-alpha-4,
  card: $dark-bg-lighter-5,
  dialog: $dark-bg-lighter-5,
  tooltip: $dark-bg-lighter-20,
  disabled-button: $light-bg-alpha-12,
  raised-button: $dark-bg-lighter-5,
  focused-button: $light-focused,
  selected-button: $dark-bg-lighter-20,
  selected-disabled-button: $dark-bg-lighter-30,
  disabled-button-toggle: $dark-bg-lighter-10,
  unselected-chip: $dark-bg-lighter-20,
  disabled-list-option: $dark-bg-lighter-10,
);

// Compute font config
@include mat-core($fontConfig);

// Theme Config

body {
  --primary-color: #0081c9;
  --primary-lighter-color: #b3d9ef;
  --primary-darker-color: #0064b5;
  --text-primary-color: #{$dark-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};

}
$mat-primary: (
  main: #0081c9,
  lighter: #b3d9ef,
  darker: #0064b5,
  200: #0081c9,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: #00edff;
  --accent-lighter-color: #b3faff;
  --accent-darker-color: #00e5ff;
  --text-accent-color: #{$dark-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$dark-primary-text};
}
$mat-accent: (
  main: #00edff,
  lighter: #b3faff,
  darker: #00e5ff,
  200: #00edff,
  // For slide toggle,
  // For slide toggle,
  contrast:
    (
      main: $dark-primary-text,
      lighter: $dark-primary-text,
      darker: $dark-primary-text,
    ),
);
$theme-accent: mat-palette($mat-accent, main, lighter, darker);

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: #{$light-primary-text};
  --text-warn-lighter-color: #{$dark-primary-text};
  --text-warn-darker-color: #{$light-primary-text};
}
$mat-warn: (
  main: #ff0000,
  lighter: #ffb3b3,
  darker: #ff0000,
  200: #ff0000,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-warn: mat-palette($mat-warn, main, lighter, darker);

$altTheme: mat-dark-theme($theme-primary, $theme-accent, $theme-warn);
$theme: mat-light-theme($theme-primary, $theme-accent, $theme-warn);

// Theme Init
@include angular-material-theme($theme);

.theme-alternate {
  @include angular-material-theme($altTheme);
}

// Specific component overrides, pieces that are not in line with the general theming

// Handle buttons appropriately, with respect to line-height
.mat-raised-button,
.mat-stroked-button,
.mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
  .mat-badge-content {
    font-family: "Popins";
  }
}

.mat-step-header .mat-step-label.mat-step-label-active {
  color: rgba($dark-text, 0.54);
}
// .mat-step-header .mat-step-icon {
//   background-color: #0081c98a;
//   color: white;
// }

// .mat-flat-button.mat-primary,
// .mat-raised-button.mat-primary,
// .mat-fab.mat-primary,
// .mat-mini-fab.mat-primary {
//   color: white;
// }

// .mat-flat-button.mat-accent,
// .mat-raised-button.mat-accent,
// .mat-fab.mat-accent,
// .mat-mini-fab.mat-accent {
//   color: white;
// }

.mat-h2,
.mat-h1,
.mat-h3,
.mat-h4,
.mat-title,
.mat-typography h1,
.mat-typography h2,
.mat-typography h3,
.mat-typography h4 {
  margin: initial;
}

.mat-form-field {
  width: 100%;
  letter-spacing: normal;
  // font-size: inherit;
  // font-weight: 400;
  // line-height: 1.125;
  // font-family: Roboto;
}

.mat-select-placeholder {
  color: #272129 !important;
  opacity: 0.5 !important; /* Firefox */
}

::placeholder {
  color: #272129 !important;
  opacity: 0.5 !important; /* Firefox */
}

.mat-select-value {
  color: #272129 !important;
}

.mat-select-arrow {
  color: #272129 !important;
  opacity: 0.5 !important; /* Firefox */
}

.mat-option.mat-active {
  background: rgba(170, 170, 170, 0.24);
  color: #000000;
}

.mat-option {
  color: rgb(0, 0, 0);
}

// .mat-flat-button.mat-primary.mat-button-disabled,
// .mat-flat-button.mat-accent.mat-button-disabled,
// .mat-flat-button.mat-warn.mat-button-disabled,
// .mat-flat-button.mat-button-disabled.mat-button-disabled,
// .mat-raised-button.mat-primary.mat-button-disabled,
// .mat-raised-button.mat-accent.mat-button-disabled,
// .mat-raised-button.mat-warn.mat-button-disabled,
// .mat-raised-button.mat-button-disabled.mat-button-disabled,
// .mat-fab.mat-primary.mat-button-disabled,
// .mat-fab.mat-accent.mat-button-disabled,
// .mat-fab.mat-warn.mat-button-disabled,
// .mat-fab.mat-button-disabled.mat-button-disabled,
// .mat-mini-fab.mat-primary.mat-button-disabled,
// .mat-mini-fab.mat-accent.mat-button-disabled,
// .mat-mini-fab.mat-warn.mat-button-disabled,
// .mat-mini-fab.mat-button-disabled.mat-button-disabled {
//   background-color: #0081c9a6;
// }
.mat-select-panel {
  background-color: #ffffff;
}
.mat-autocomplete-panel {
  background: #ffffff;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}
.mat-autocomplete-panel .mat-option.mat-selected:not(.mat-active):not(:hover) {
  background: #ffffff;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #ffffff;
  opacity: 1;
}

.mat-datepicker-content {
  box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%);
  background-color: white;
  color: rgba(0, 0, 0, 0.54);
}

.mat-calendar-body-cell-content,
.mat-date-range-input-separator {
  color: rgba(0, 0, 0, 0.54);
  border-color: transparent;
}

.successSnackBar {
  color: #155724 !important;
  background-color: #d4edda !important;
  border-color: #c3e6cb !important;
  word-break: break-word;
  .mat-simple-snackbar-action {
    color: #155724 !important;
  }
}

.warningSnackBar {
  color: #856404 !important;
  background-color: #fff3cd !important;
  border-color: #a7a08c !important;
  word-break: break-word;
  .mat-simple-snackbar-action {
    color: #856404 !important;
  }
}

.dangerSnackBar {
  color: #721c24 !important;
  background-color: #f8d7da !important;
  border-color: #f5c6cb !important;
  word-break: break-word;
  .mat-simple-snackbar-action {
    color: #721c24 !important;
  }
}

.mat-card {
  background: white;
  color: black;
  padding: 1em 2em !important;
  overflow: auto;
  border-radius: 10px !important;
}

.mat-drawer-container {
  // background-color: #ffffff;
  // background-color: #e7e9f2;
  color: rgb(0, 0, 0);
 // background-color: #f3f6f9 !important;
}
.mat-sidenav {
  // background-color: #fafafa;
  background-color: #ffffff;
}

.mat-menu-item {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
}

.mat-checkbox-layout {
  white-space: normal !important;
}

.mat-form-field-appearance-legacy
  .mat-form-field-suffix
  .mat-datepicker-toggle-default-icon {
  width: 1.5em !important;
  height: 1.5em !important;
}

.mat-paginator {
  background: transparent;
}

.mat-cell {
  color: black !important;
}
.mat-table {
  width: 100%;
}

.mat-button-toggle-appearance-standard {
  color: black;
  background: white;
}

.mat-button-toggle-checked.mat-button-toggle-appearance-standard {
  color: white;
  background: var(--primary-color);
}

.active-premium {
  font-weight: bold;
  font-size: 1.2em;
  color: rgb(11, 168, 32);
}