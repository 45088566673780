@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&display=swap');

html,
body {
  height: 100%;
}
body {
  margin: 0;
  //font-family: Poppins, Helvetica, "sans-serif";
  font-family: 'Dosis', sans-serif;
  // background-image: linear-gradient(90deg, #0081C9 0%, #00edff 100%);
}

.cursor-pointer {
  cursor: pointer;
}



/*
  START Override bootstrap styles
*/
.mat-form-field-appearance-legacy .mat-form-field-label {
  color: rgba(0, 0, 0, 0.54);
  font-size: 22px;
}


.primary {
  color: #0081c9;
}

.secondary {
  color: #00EDFF;
}

.btn-primary {
  background-color: #0081c9 !important; 
  color: #ffffff !important;
}

/*
  END Override bootstrap styles
*/


.form-control:is(mat-select){
  border: none !important;
}

/*
  Start Color
*/
.white {
  color: white;
}

.red {
  color: #ff0000;
}

.black {
  color: #000000;
}
.gray {
  color: #8d8d8d;
}
.white-blue {
  color: #e7e9f2;
}

.light-gray {
  color: #b5b5c3;
}

.blue {
  color: var(--primary-color);
}
/*
  End Color
*/

/*
  Start backgrounds
*/
.bg-white {
  background-color: white;
}

.bg-red {
  background-color: #ff0000;
}

.bg-gray {
  background-color: #8d8d8d;
}
.bg-white-blue {
  background-color: #f3f6f9;
}

.bg-light-blue {
  background-color: #f3f6f9;
}

/*
  End backgrounds
*/

/*
  Start Flex
*/
.flex-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-center-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.flex-end {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}
.flex-start {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}

.flex-start-center {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: center;
}

.flex-start-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

.flex-space-between {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.flex-space-between-column {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
}

.flex-space-around {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  align-content: center;
}

.no-wrap {
  flex-wrap: nowrap;
}

/*
  End Flex
*/

/*
  Start Padding
*/
.padding-05 {
  padding: 0.5rem !important;
}

.padding-top-05 {
  padding-top: 0.5rem !important;
}
.padding-bottom-05 {
  padding-bottom: 0.5rem !important;
}
.padding-vertical-05 {
  padding-bottom: 0.5rem !important;
  padding-top: 0.5rem !important;
}
.padding-left-05 {
  padding-left: 0.5rem !important;
}
.padding-right-05 {
  padding-right: 0.5rem !important;
}
.padding-horizontal-05 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}
.padding-1 {
  padding: 1rem !important;
}
.padding-top-1 {
  padding-top: 1rem !important;
}
.padding-bottom-1 {
  padding-bottom: 1rem !important;
}
.padding-vertical-1 {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}
.padding-left-1 {
  padding-left: 1rem !important;
}
.padding-right-1 {
  padding-right: 1rem !important;
}
.padding-horizontal-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.padding-2 {
  padding: 2rem !important;
}
.padding-top-2 {
  padding-top: 2rem !important;
}
.padding-bottom-2 {
  padding-bottom: 2rem !important;
}
.padding-vertical-2 {
  padding-bottom: 2rem !important;
  padding-top: 2rem !important;
}
.padding-left-2 {
  padding-left: 2rem !important;
}
.padding-right-2 {
  padding-right: 2rem !important;
}
.padding-horizontal-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
/*
  Start Padding
*/
/*
  Start Margin
*/
.margin-05 {
  margin: 0.5rem !important;
}

.margin-top-05 {
  margin-top: 0.5rem !important;
}
.margin-bottom-05 {
  margin-bottom: 0.5rem !important;
}
.margin-vertical-05 {
  margin-bottom: 0.5rem !important;
  margin-top: 0.5rem !important;
}
.margin-left-05 {
  margin-left: 0.5rem !important;
}
.margin-right-05 {
  margin-right: 0.5rem !important;
}
.margin-horizontal-05 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}
.margin-1 {
  margin: 1rem !important;
}
.margin-top-1 {
  margin-top: 1rem !important;
}
.margin-bottom-1 {
  margin-bottom: 1rem !important;
}
.margin-vertical-1 {
  margin-bottom: 1rem !important;
  margin-top: 1rem !important;
}
.margin-left-1 {
  margin-left: 1rem !important;
}
.margin-right-1 {
  margin-right: 1rem !important;
}
.margin-horizontal-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.margin-2 {
  margin: 2rem !important;
}
.margin-top-2 {
  margin-top: 2rem !important;
}
.margin-bottom-2 {
  margin-bottom: 2rem !important;
}
.margin-vertical-2 {
  margin-bottom: 2rem !important;
  margin-top: 2rem !important;
}
.margin-left-2 {
  margin-left: 2rem !important;
}
.margin-right-2 {
  margin-right: 2rem !important;
}
.margin-horizontal-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.margin-auto {
  margin: 0 auto;
  // align-items: center;
  // text-align: center;
}
/*
  End Margin
*/

/*
  Content
*/
.container-form {
  max-width: 600px !important;
}
/*
  Content
*/

/*
  width
*/
.width-50 {
  width: 50%;
}
.width-80 {
  width: 80%;
}
.width-100 {
  width: 100%;
}
/*
  width
*/

/*
  Text
*/
.text-left {
  text-align: left;
}
.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-justify {
  text-align: justify;
}
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.bold {
  font-weight: bold !important;
}
/*
  Text
*/

button.mat-raised-button {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  width: 50%;
  min-width: fit-content;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: 0.2s background-color;
}

button.mat-raised-button:hover {
  background: white;
  color: var(--primary-color);
  box-shadow: 0px 0px 0px 2px var(--primary-color);
  // border: 2px solid var(--primary-color);
  // font-weight: bold;
}

.crossed-bg {
  background: #d8d8d8;
  height: 1px;
  margin-top: 10px;

  span {
    background: #fff;
    padding: 10px;
  }
}

// Spinner
@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
button.spinner {
  color: transparent !important;
}
.spinner.mat-primary:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #00edff;
  animation: spinner 0.8s linear infinite;
}

.spinner:before {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #ffffff;
  border-top-color: #0081c9;
  animation: spinner 0.8s linear infinite;
}

// Card
.scheedule-card {
  // @media (min-width: 599px) {
  //   width: 46%;
  // }
  width: 100%;
  // margin: 10px;
}

// Inputs
input.inside {
  padding: 0;
  margin: 0;
  width: 100%;
  border: 0px;
}
input.inside:focus-visible {
  border: 0px;
  outline: none;
}

// Links
a:focus-visible {
  outline-offset: 0px;
  outline: none;
}

/* Importing Bootstrap SCSS file. */
@import "~bootstrap/scss/bootstrap";

// MatMenus
.search-teacher-backdrop {
  background: rgba(0, 0, 0, 0.3);
}

.search-teacher-menu {
  min-width: auto !important;
  width: 100%;
  max-width: 500px !important;
}

// Icon
.question-icon {
  width: 20px;
  height: 20px;
  color: var(--primary-color);
}

.social-network-icons {
  font-size: 24px;
  margin-right: 5px;
}

.social-network-icons:hover {
  transition: 0.2s color;
  color: var(--primary-color);
  cursor: pointer;
}

// Scroll always visible
.mat-select-panel::-webkit-scrollbar, .fc-scroller::-webkit-scrollbar {
  -webkit-appearance: none;
}

.mat-select-panel::-webkit-scrollbar:vertical,.fc-scroller::-webkit-scrollbar:vertical  {
  width: 11px;
  background-color: rgba(0, 0, 0, 0.068);
}

.mat-select-panel::-webkit-scrollbar-thumb, .fc-scroller::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid rgba(0, 0, 0, 0.068)!important;
  /* should match background, can't be transparent */
  background-color: var(--primary-color);
}
